import { Button, Typography, Box } from '@mui/material'
import NotFoundIcon from 'assets/images/not-found.svg'
import { ReactComponent as Logo } from 'assets/images/logo.svg'

export default function NotFound() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      component="main"
      maxWidth="500px"
      margin="0 auto"
      height="100vh"
      paddingTop={3}
    >
      <Logo />
      <Box sx={{ marginY: 5 }} component="img" src={NotFoundIcon} alt="Not found" />
      <Typography sx={{ marginBottom: 2 }} variant="h2">
        Page not found
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        You might not have permissions to see this page.
      </Typography>
      <Button size="small" variant="contained" href="/">
        Back to Home
      </Button>
    </Box>
  )
}
